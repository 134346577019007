import httpClient from '@api/httpClient'
import type { Contact, Depot } from '@models/depot'
import { OrderCheckGeoPosition } from '@models/order'
import { depotsUrls } from './constants'

const getDepots = async (companyId: string) => {
  const { data } = await httpClient.get<Depot[]>(depotsUrls.root, {
    params: { companyId },
  })
  return data
}

export interface CreateDepotRequestData {
  companyId: string
  depotExternalId: string
  name?: string
  address?: string
  checkRoutesInterval: number
  checkGeoPositionRadius: number
  checkGeoPosition: OrderCheckGeoPosition
  contacts: Contact[]
}

const createDepot = async (props: CreateDepotRequestData) => {
  const { data } = await httpClient.post<Depot>(depotsUrls.root, props)
  return data
}

export interface UpdateDepotRequestData
  extends Omit<CreateDepotRequestData, 'depotExternalId'> {
  id: string
}

const updateDepot = async ({ id, ...props }: UpdateDepotRequestData) => {
  const { data } = await httpClient.patch<Depot>(depotsUrls.depot(id!), props)
  return data
}

export default {
  getDepots,
  createDepot,
  updateDepot,
}
