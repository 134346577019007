import { PreferencesConfig } from '@models/company'

export const DEFAULT_ORDER_COLUMNS = [
  'userFullName',
  'customerName',
  'orderExternalId',
  'status',
  'date',
  'proofDelivery',
  'paymentMethod',
  'actualArrivalDate',
  'cost',
  'settings',
  'cancelOrder',
  'actions',
] as const

export const getDefaultOrderTabs = (
  isSimplifiedOrdersCompany: boolean,
): PreferencesConfig['orders']['orderInfo']['navigation']['tabs'] => ({
  wares: {
    index: 0,
    hidden: isSimplifiedOrdersCompany,
  },
  orderInfo: {
    index: 1,
    hidden: false,
    data: {
      items: [
        'status',
        'client',
        'courier',
        'plannedTime',
        'actualTime',
        'payment',
        'reason',
        'notes',
      ],
    },
  },
  eta: {
    index: 2,
    hidden: true,
  },
  goodsDeliveryNote: {
    index: 3,
    hidden: false,
  },
})

export const PREFERENCES: { [key: string]: PreferencesConfig } = {
  v1a3131: {
    depots: {
      hidden: true,
    },
    orders: {
      hidden: false,
      exportToXls: false,
      filters: {
        date: {
          hidden: false,
          defaultValue: [0, 1],
          isCustomComponent: true,
        },
        depotId: {
          hidden: true,
        },
        companyPartnerId: {
          hidden: false,
        },
        searchText: {
          hidden: false,
          isSearchAcrossAllDates: true,
          translations: {
            placeholder:
              'pages.companies.orders.filters.order_search_client_placeholder',
          },
        },
      },
      table: {
        columns: [
          'customerName',
          'orderExternalId',
          'invoiceId',
          'address',
          'orderExternalPartnerId',
          'partnerName',
          'status',
          'proofDelivery',
          'actualArrivalDate',
          'settings',
        ],
      },
      navigation: {
        tabs: ['orders'],
      },
      orderInfo: {
        navigation: {
          tabs: {
            orderInfo: {
              index: 0,
              hidden: false,
              data: {
                items: [
                  'status',
                  'client',
                  'courierNoPhone',
                  'address',
                  'plannedTime',
                  'actualTime',
                  'notes',
                ],
              },
            },
            wares: {
              index: 1,
              hidden: false,
            },
            eta: {
              index: 2,
              hidden: false,
            },
            goodsDeliveryNote: {
              index: 3,
              hidden: false,
            },
          },
        },
      },
    },
    users: {
      hidden: false,
      roles: {
        availableRoles: ['COMPANY_MANAGER', 'COMPANY_REGIONAL_MANAGER'],
        translations: {
          COMPANY_REGIONAL_MANAGER: 'pages.companies.users.form.partner',
        },
      },
    },
    partners: {
      hidden: false,
    },
    routes: {
      hidden: true,
    },
    waresToDepot: {
      hidden: true,
    },
    finances: {
      hidden: true,
    },
    settings: {
      homePage: 'orders',
      theme: 'v1a3131',
    },
  },
}
