import { financesApi, financesKeys } from '@api'
import { FinanceFilters } from '@models/finance'
import { useQuery } from 'react-query'

const useFinancesCount = (filters: FinanceFilters) => {
  const query = useQuery(financesKeys.financesCount(), async () => {
    const data = await financesApi.getFinancesCount(filters)
    return data
  })

  return query
}

export default useFinancesCount
